import styled from "styled-components"

export const RichTextBannerStyles = styled.section`
  position: relative;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 110px 30px 80px 30px;
    background-color: #efecea;

    h1 {
      width: 100%;
      margin-top: 0;
      font-size: 72px;
      line-height: 78px;
      font-weight: 700;
      letter-spacing: -2px;
      display: block;
    
      @media (max-width: 1024px) {
        font-size: 48px;
        line-height: 47px;
      }
      @media (max-width: 620px) {
        font-size: 32px;
        line-height: 33px;
      }
    }

    h2 {
        width: 50%;
        margin-top: 0;
        font-size: 29px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: -0.8px;
        display: block;

        @media (max-width: 1024px) {
          width: 100%;
        }

        @media (max-width: 620px) {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  @media (min-width: 769px) {
    .container {
      padding: 110px 112px 80px 112px;
    }
  }

  @media (min-width: 1480px) {
    padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
    .container {
      padding: 110px 112px 80px 112px;
    }
  }

  @media (min-width: 1680px) {
    .container {
      max-width: 1380px;
    }
  }

  .container.news-container {
    padding-bottom: 0px;
  }

  .banner__image,
  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .gradient {
    background: radial-gradient(
      at bottom left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .banner__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 0px;
    position: relative;
    z-index: 2;
    width: 100%;

    .leftPanel, .rightPanel {
      display: flex;
      flex: 1;
      width: 50%;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      .logo-wrapper {
        align-self: flex-start;
        max-width: 300px;
        img {
          width:100%;
        }
        img:first-child {
          margin-bottom: 28px;
        }
        @media (max-width: 1024px) {
          max-width: 200px;
        }

      }
      .sub-para {
          // margin-top: auto; /* Pushes the sub-nav to the bottom */
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .rightPanel {
      align-items: flex-start;

      @media (min-width: 768px) {
        min-width: 294px;
      }
    }

    .leftPanel {
      align-items: flex-start;
      max-width: 884px;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 102px;
    }

    /* h1 {
      margin-top: 0;
      font-size: 72px;
      line-height: 78px;
      font-weight: 700;
      letter-spacing: -2px;
      display: inline-block;
    
      @media (max-width: 1024px) {
        font-size: 48px;
        line-height: 47px;
      }
      @media (max-width: 620px) {
        font-size: 32px;
        line-height: 33px;
      }
    } */

    h2 {
        font-size: 29px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: -0.8px;
        display: inline-block;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: var(--textBlack);
    }

    ul {
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`
