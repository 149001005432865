import React from "react";
import { graphql } from "gatsby";
import NewsArticleTemplate from "../../templates/news-article-template";
import Seo from "../../components/SEO";
import Layout from "../../components/Layout";

const NewsArticle = ({ data: { contentfulNews } }) => {
  return (
    <>
      <Seo title={contentfulNews.title} />
      <Layout>
        <NewsArticleTemplate {...contentfulNews} />
      </Layout>
    </>
  )
}

export const data = graphql`
  query newsQuery($id: String) {
    contentfulNews(id: { eq: $id }) {
      title
      datePublished
      url
      videoUrl
      bannerImage {
        gatsbyImageData
        file {
          url
        }
        description
      }
      mainContentBlock {
        ... on ContentfulTextBlock {
          id
          internal {
            type
          }
          title
          singleColumn
          mainContent {
            raw
          }
        }
        ... on ContentfulAlignedImageBlock {
          id
          internal {
            type
          }
          title
          alignment
          mainText {
            raw
          }
          images {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            description
            file {
              url
            }
          }
        }
        ... on ContentfulImageBlock {
          internal {
            type
          }
          title
          image {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            description
            file {
              url
            }
          }
        }
        ... on ContentfulVideoBlock {
          internal {
            type
          }
          title
          videoUrl
          description
        }
      }
    }
  }
`;

export default NewsArticle;
