import React from "react";
import useAllCampaigns from "../hooks/use-all-campaigns";
import useAllClients from '../hooks/use-all-clients';
import styled from "styled-components";
import { ClientSlider } from "../components/ClientsSlider/ClientSlider";
import { MainContentBlockHandler } from "../components/MainContentBlockHandler/MainContentBlockHandler";
import { RichTextBanner } from "../components/RichTextBanner/RichTextBanner";
import { RichTextBannerStyles } from "../components/RichTextBanner/RichTextBannerStyles";
import { StaticImageBanner } from "../components/StaticImageBanner/StaticImageBanner";
import { TileSlider } from "../components/TileSlider/TileSlider";
import { transformTileContent } from '../utils/transform-data-functions';


const NewsArticleTemplateStyles = styled.div`
  .case-study-slider {
    .section {
      border-bottom: 0px;
    }
  }
`;

const NewsArticleTemplate = (newsArticle) => {
  const {
    title,
    url,
    videoUrl,
    bannerImage,
    mainContentBlock,
  } = newsArticle;

  const clients = useAllClients();
  const campaigns = transformTileContent(useAllCampaigns());


  return (
        <NewsArticleTemplateStyles>
          <StaticImageBanner image={bannerImage} title={title} video={videoUrl} />
          <RichTextBanner title={title} hasDot={true} isNews={true} />
          <MainContentBlockHandler content={mainContentBlock} />
          <TileSlider className="case-study-slider" heading="Campaigns" intro="Our amazing team deliver incredible results across a variety of specialist services." tiles={campaigns} ctaText="View All" ctaLink="/campaigns" />
          <ClientSlider heading="Clients." logos={clients} />
        </NewsArticleTemplateStyles>
  )
}

export default NewsArticleTemplate;