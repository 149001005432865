import * as React from "react"
import { RichTextRenderer } from "../RichTextRenderer/RichTextRenderer";
import { RichTextBannerStyles } from "./RichTextBannerStyles"

export const RichTextBanner = ({ title, subHeader, introPara, subPara, logos, hasDot=true, isNews=false }) => {
  return (
    <RichTextBannerStyles>
      {title && (
        <div className={`container${isNews ? ' news-container' : ''}`}>
          <h1>
              {title}
              {hasDot && <span style={{ color: "var(--bodyColor)" }}>.</span>}
          </h1>
          {subHeader && <h2>{subHeader}</h2>}
          <div className="banner__content">
            <div className="leftPanel">
            {introPara && (
                <RichTextRenderer
                  richTextJson={introPara.raw}
                  references={introPara.references || []}
                />
              )}
            </div>
            <div className="rightPanel">
              {logos && <div className="logo-wrapper">
                {logos.map((logo, index) => (
                <img key={index} src={logo?.file?.url} alt={logo.description} />
              ))}</div>}
              {subPara && <div className="sub-para"><RichTextRenderer richTextJson={subPara.raw} /></div>}
            </div>
          </div>
        </div>
      )}
    </RichTextBannerStyles>
  )
}