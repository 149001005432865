import styled from "styled-components"

export const StaticImageBannerStyles = styled.section`
  /* height: 70vh; */
  position: relative;

  .container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 80px 30px 0px 30px;
    background-color: #efecea;

    img {
        width: 100%;
    }

    .video-container {
      position: relative;
      width: 100%; /* Ensure the container is full width */
      padding-top: 56.25%; /* 16:9 Aspect Ratio */

      iframe {
        border: none;
      }

      .video-loading {
        position: absolute;
        display: flex;          /* Enables Flexbox */
        justify-content: center; /* Centers content along the main axis (horizontal) */
        align-items: center; 
        top: 1px;
        left: 0px;
        width: 100%;
        height: 100%;
        color: #fff;
        background-color: rgb(33, 33, 33);

        img {
          max-width: 64px;
        }
      }
    }

    .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 769px) {
    .container {
      padding: 80px 112px 0px 112px;
    }
  }

  @media (min-width: 1480px) {
    padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
    .container {
      padding: 80px 112px 0px 112px;
    }
  }

  @media (max-width: 1024px) {
    .container {
      padding: 80px 0 0 0;
    }
  }

  @media (min-width: 1680px) {
    .container {
      max-width: 1380px;
    }
  }
`