import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StaticImageBannerStyles } from "./StaticImageBannerStyles"

export const StaticImageBanner = ({ image, title, video }) => {
  const gatsbyImage = getImage(image)
  return (
    <StaticImageBannerStyles>
        <div className="container">
          {video ? 
            (<div className="video-container">
              <div className="video-loading">
                  <img src="/images/loader.gif" alt="Loading Video..." />
                </div>
              <iframe src={video} allow="autoplay; fullscreen;" title={title}></iframe>
            </div>) : (
              image && gatsbyImage && 
                <GatsbyImage image={gatsbyImage} alt={image.description || ""} />
            )
          }
        </div>
    </StaticImageBannerStyles>
  )
}